import { SqlUser } from '../../admin/models/users';
import { BanMuteInfo } from './ban-mute-info';
import { UserFullInfo } from './user';


//CODE_TO_LIVE
export class MicrophoneJson {
    M: string = ""; //On main microphone userId
    M2: Array<string> = []; // on other micrpohone users id list
    H: Array<string> = []; // on hands up users id list
    R: string; //roomid
}

export class Room {
    I: number; // room ID
    N: string; // room name
    O: number; // online count
    C: number; // camera count
    M: string; // room message
    W: string; // owner name
    F: boolean;// is favorite room
    FF: string;// font family //ODA LİSTTE GÖRÜNEN ODANIN ADININ FONTU
    CO: string;// color //ODA LİSTTE GÖRÜNEN ODANIN ADININ COLORI
    II: string;// image id //ODA LİSTTE GÖRÜNEN ODANIN IMAGE ID
    IN: string;// index //ODA LISTIN SIRALAMA INDEXI
    HP:boolean
}
export class RoomSimpleInfo {
    RoomName: string;
    RoomId: number;
    OwnerPicture:string
}


export const FakeRoomAudioConfereneceUserId = "null";

export class RoomInfo {
    AKTIF: boolean;
    BACK_IMAGE_ID: string;
    BASLANGIC: Date;
    BITIS: Date;
    BUTTON_BACK_IMAGE_ID: string;
    BUTTON_FONT: string;
    BUTTON_FONTCOLOR: string;
    HESAPID: number;
    ID: number;
    KIMLER_GIREBILIR: string;
    LIMIT: number;
    MICLIMIT: number;
    NAME: string;
    ODAMESAJ: string;
    OWNER: number;
    OwnerName: string;
    RENK_CHAT_1: string;
    RENK_CHAT_2: string;
    RENK_USER_1: string;
    RENK_USER_2: string;
    SESSIZ: boolean;
    TOPIC: string;
    YAZILARI_SIL: boolean;
    SIFRE: string;
    TBLVC_HESAP: RoomServerInfo;
    TBLVC_USER: SqlUser;
    BACK_IMAGE_BLOB: string;
    BUTTON_BACK_IMAGE_BLOB: string;
    ROOM_BACK_IMAGE_IS_GIF: boolean;
    BackImageType: string;
    HASPASSWORD: boolean;
    RutbeDerece: number;
    ProfilResmiId: string;
    GUEST_JOIN:boolean;
    SIP_IS_ON:boolean;
    pin:string
	isHidePhoneNumber:boolean
    number:string
    username:string
    password:string
    server:string
    AUDIO_QUALITY:number
    IS_OPEN_SAVE_CHAT:boolean
    CAN_SHARE_FILE:boolean
    IsPrivate:boolean
    TemplateID:number
    Order:number
    ROOM_PASSWORD:string
    Point:number
    Stats:number
}

export enum RoomInfoEnum {
    ID = 0,
    NAME = 1,
    ODAMESAJ = 2,
    TOPIC = 3,
    BACK_IMAGE_ID = 4,
    BUTTON_BACK_IMAGE_ID = 5,
    BUTTON_FONT = 6,
    BUTTON_FONTCOLOR = 7,
    KIMLER_GIREBILIR = 8,
    LIMIT = 9,
    MICLIMIT = 10,
    RENK_CHAT_1 = 11,
    RENK_CHAT_2 = 12,
    RENK_USER_1 = 13,
    RENK_USER_2 = 14,
    SESSIZ = 15,
    YAZILARI_SIL = 16,
    SIFRE = 17,  // "-"  // şifreli ise -döndeğilse boş dön
    OWNER = 18,
    BACK_IMAGE_TYPE = 19,
    HAS_PASSWORD = 20
}
export enum UserListType {
    VIP = 0,
    ONLINE = 1,
    REQUEST = 2
}

export enum RoomListType {
    STATIC = 0,
    DYNAMIC = 1,
}

export class RoomHelper {
    LastEnterRoomTime: Date;
    ChangeRoomIsInActive: boolean;
    OnlineCount: number;
    CurrentRoomInfo: RoomInfo;
    ControlBeforeEnterRoomInfo: any;
    AutoEnterWithSecondNick: boolean;
    RoomUserList: any[];
    RoomSubscribeLocker:
        {
            RoomChannel: boolean,
            RoomSettingChannel: boolean
        };
    CurrentMessageSendingType: number;// _enums.MessageSendingType.Normal;
    EnterRoomFirst: boolean;
    ChatPanelProperties: any;
    AutoScrollActiveInChatArea: boolean;
    RoomPanel: any;
    HandButtonLastClickDate: Date;
    CamButtonLastClickDate: Date;
}

export class RoomOwnerInfo {
    KullaniciId: number;
    KullaniciAdi: string;
    ProfilResmiId: string;
    RutbeId: number;
    RutbeDerece: number;
    RutbeAdi: string
}
export class RecordRoomVideoType {
    videoUrl: string;
    videoType: string
}


export class RoomServerInfo {
    ID: number;
    HESAPADI: string;
    HESAPSIFRE: string;
    DURUM: boolean;
    WEBSYNCSERVERURL: string;
    ICELINKSERVERURL: string;
    MERKEZ: boolean;
    MICSERVICEURL: string;
    GROUPCONFERENCESERVICEURL: string
}

export interface RoomMicToken{
    token:string;
    roomId:string;
    error?:string
}

export class BeforeEnterRoom {
    SecondNick: string;
    BanMuteInfo: BanMuteInfo;
    RoomInfo: RoomInfo;
    RoomOwnerInfo: RoomOwnerInfo;
    RoomServerInfo: RoomServerInfo;
    RoomOnlineCount: number;


}

export class EnterRoomInfo {
    I: number; // UserID
    N: string; // UserName
    O: string; // OperationSystem,
    P: string; // Password
    M: string; // MachineNumber
    D: string; // Date
    RI: number; // RankID
    RD: number; // RankDegree
    RN: string; // RankName
    S: string;  // ?
    OI: number; // RoomID
    OA: string; // RoomName
}

export class RoomMicrophoneAccepting {
    ParentItem: any;
    TimerNumber: number;
    StopTimerFunc: any;
    AcceptMicrophoneFunc: any;
}

export class FavoriteRoom {
    OdaId: number;
    OdaAdi: string;
}


export class RoomJson {
   data:RoomJsonData //Array<Room> json string
   count:number
}
export class RoomJsonData {
    I: number;
    R: Array<Room>; //Array<Room> json string
}
export class ID {
    I: number

}

export class RoomOnlineCount {
    I: number;
    O: number;
}

export class RoomCameraCount {
    I: number;
    C: number;
}

export class RoomCount {
    room_id: number;
    online_count: number;
}




export class CurrentRoom {
    Info: RoomInfo | null = null;
    OwnerInfo: UserFullInfo | null = null;
    OnlineCount: number = 0;
}

export class RoomCamera {
    userId: number;
    userName: string;
    roomId: number;
    icelinkSession: string;
    videoContainer: HTMLVideoElement;
    isScreen: boolean;
}

export class RoomCameraWatcher {
    userId: number;
    userName: string;
}

export class RedisRootSettings {
    OdaBanGirisleri: boolean;
    SiteBanGirisleri: boolean;
}
export class RoomRecord{
    id: number
    EgressID: string
    RoomName: string
    Status: boolean
    StartedAt: string
    EndedAt: string
    PlaylistName: string
    PlaylistURL: string
    OnlyAudio:boolean
}